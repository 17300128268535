<template>
  <div class="create-category">
    <error :errors="categoryError" />
    <category-form />
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import categoryForm from "../../../components/collection/collectionForm";

  import Error from "../../../partials/widgets/Error";
  import { mapGetters } from "vuex";
  export default {
    name: "index",
    components: { categoryForm, Error },
    computed: {
      ...mapGetters(['categoryError'])
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: " تۈر باشقۇرۇش", route: "list" },
        { title: "يېڭىدىن قوشۇش" }
      ]);
    }
  }
</script>
